import Signature from '@/models/Signature';
import User from '@/models/User';
import Location from '@/models/Location';
import Customer from '@/models/Customer';
import CleanTime from '@/models/CleanTime';
import Task from '@/models/Task';
import Image from '@/models/Image';
import moment from 'moment';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';
import {VersionControl} from '@/misc/VersionControl';

export enum WorkSessionType {
  QUALITY_ASSURANCE = 'qualityAssurance',
  CUSTOMER_CONVERSATION = 'customerConversation',
  EMPLOYEE_CONVERSATION = 'employeeConversation',
  MATERIAL_DELIVERY = 'materialDelivery',
  OTHERS = 'others',
  SPECIAL_JOB = 'specialJob',
}

export default class WorkSession extends VersionControl {

  public static parseFromObject(object: Partial<WorkSession>): WorkSession {
    const workSession = new WorkSession();
    Object.assign(workSession, object);

    if (object.customer) {
      object.customer = Customer.parseFromObject(object.customer!) as Customer;
    }
    object.employeeSign = object.employeeSign && Signature.parseFromObject(object.employeeSign!);
    object.customerSign = object.customerSign && Signature.parseFromObject(object.customerSign!);
    if (object.user) {
      object.user = User.parseFromObject(object.user!);
    }

    return workSession;
  }

  public id!: string;
  /**
   * Value in ms how long the employee worked on this session. Based on startTime and endTime
   * Employee is able to change this date 10 minutes after last update.
   */
  public duration!: number;

  /**
   * Duration value in readable format
   */
  public durationHumanized!: { [key: string]: string };

  /**
   * Value in ms how long the worksession was active. Based on createdAt and durationSetAt
   */
  public durationTracked!: number;

  /**
   * DurationTracked value in readable format
   */
  public durationTrackedHumanized!: {[key: string]: string};

  /**
   * The point of time when the work session was completed
   */
  public durationSetAt?: string;
  /**
   * The point of time when the work session was created and therefore started
   */
  public createdAt!: string;

  /**
   * ALl task that were completed in the worksession
   */

  public checkedTasks!: Task[];

  /**
   * The user defined start time of the work session
   */
  public startTime!: string;

  /**
   * The user defined end time of the work session
   */
  public endTime!: string;

  public customer?: Customer | null;
  public customerId!: string | null;

  public canceledByReason?: string;

  public user: User | undefined;
  public userId!: string;

  public comment?: string | null;

  public cleanTime?: CleanTime | null;
  public cleanTimeId!: string | null;
  public cleanTimeOccurrence?: string | null;

  public images: Image[] = [];

  public employeeSign!: Signature | null;
  public customerSign!: Signature | null;

  public location?: Location | null;
  public locationId!: string | null;

  public hasImages?: boolean | null;

  public times?: any;

  public workSessionType?: WorkSessionType | null;

  public parseToObject() {
    return {...this};
  }

  public get hasComments(): boolean {
    return this.hasImages && !!this.images.length || !!this.comment;
  }

  /**
   * Set the start- and endTime of a workSession
   * @param startTime
   * @param endTime
   * @param occurrence
   */
  public setTimes(startTime: string, endTime: string, occurrence: CleanTimeOccurrence) {
    const startMoment = moment(occurrence.start);
    const endMoment = moment(occurrence.end);
    // if a startTime is selected, change the startTime.
    if (startTime) {
      const [hours, minutes] = startTime.split(':');
      startMoment.set('hours', +hours);
      startMoment.set('minutes', +minutes);
    }
    // same for endTime
    if (endTime) {
      const [hours, minutes] = endTime.split(':');
      endMoment.set('hours', +hours);
      endMoment.set('minutes', +minutes);
    }
    // gets the duration by the start and endTime
    this.duration = endMoment.diff(startMoment);
    if (this.duration < 0) {
      // if end is on the next day, get proper duration
      this.duration = 1000 * 60 * 60 * 24 + this.duration;
    }

    this.startTime = startMoment.toISOString();
    this.endTime = endMoment.toISOString();
  }
}
