import Parseable from '@/misc/Parseable';
import WorkSession from '@/models/WorkSession';
import User from '@/models/User';
import Location from '@/models/Location';
import CleanTime from '@/models/CleanTime';
import Customer from '@/models/Customer';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';
import { JobStatusIdentification, JobStatusType } from '@/misc/JobStatusIdentification';

/**
 * A job represents the planning or the completed work of ACTTS from Users in Locations in a certain clean time.
 */
export default class Job extends Parseable {

    public static parseFromObject(object: Partial<Job>): Job {
        const job = new Job();

        Object.assign(job, object);

        if (object.cleanTime) {
            job.cleanTime = CleanTime.parseFromObject(object.cleanTime!);
            job.users = User.parseFromArray(object.cleanTime!.plannedUsers) as User[];
        }
        if (object.location) {
            job.location = Location.parseFromObject(object.location!);
            job.locationId = object.location.id;
        }
        if (object.customer) {
            job.customer = Customer.parseFromObject(object.customer!);
            job.customerId = object.customer.id;
        }
        job.workSessions = WorkSession.parseFromArray(object.workSessions!) as WorkSession[];

        job.status = JobStatusIdentification.identify(job);

        return job;
    }

    // New Structure of the Job
    public users: User[] = [];
    public cleanTime?: CleanTime | null;
    public customer?: Customer | null;
    public customerId?: string;
    public location?: Location | null;
    public locationId?: string;
    public cleanTimeOccurrence!: CleanTimeOccurrence;
    public workSessions: WorkSession[] = [];
    public queryDate ?: string = ''; // used to filter job in calendar week view
    public status!: JobStatusType;

    public parseToObject(): any {
        const tmp: any = {...this};
        tmp.cleanTime = tmp.cleanTime.parseToObject();
        tmp.location = tmp.location.parseToObject();
        tmp.workSessions = tmp.workSessions.slice();
        tmp.users = tmp.users.slice();
        return tmp;
    }
}

export interface JobColor {
    jobStatus: JobStatusType;
    colorCode: string;
    hatched: boolean;
}

export function getColorBrightness(colorCode: string): number {
    // Convert hex color to RGB
    const r = parseInt(colorCode.substring(1, 3), 16); // Extract and parse the red component
    const g = parseInt(colorCode.substring(3, 5), 16); // Extract and parse the green component
    const b = parseInt(colorCode.substring(5, 7), 16); // Extract and parse the blue component

    // Calculate brightness using a standard formula
    return (r * 299 + g * 587 + b * 114) / 1000;
}
