import {getColorBrightness, JobColor} from '@/models/Job';
import {JobStatusType} from '@/misc/JobStatusIdentification';

export class JobColors {
    [key: string]: Omit<JobColor, 'jobStatus'>;
}

export const DEFAULT_JOB_COLORS: JobColors = {
    [JobStatusType.IN_PROGRESS]: {
        colorCode: '#CAD9FF',
        hatched: false,
    },
    [JobStatusType.NOT_TOUCHED]: {
        colorCode: '#FFE1E1',
        hatched: false,
    },
    [JobStatusType.NOT_PLANNED]: {
        colorCode: '#FFE7D0',
        hatched: true,
    },
    [JobStatusType.IN_FUTURE]: {
        colorCode: '#E1F2FF',
        hatched: false,
    },
    [JobStatusType.DONE]: {
        colorCode: '#EFFAF6',
        hatched: false,
    },
};

export function parseJobColor(color: Omit<JobColor, 'jobStatus'>) {
    const colorCode = color.colorCode;
    const brightness = getColorBrightness(colorCode);

    // Determine text color based on brightness
    const textColor = brightness > 125 ? 'black' : 'white';

    let style;
    if (color.hatched) {
        style = `color: black; background: repeating-linear-gradient(-45deg, ${color.colorCode}, ${color.colorCode} 6px, #FFF 5px, #FFF 10px);`;
    } else {
        style = `color: ${textColor}; background-color: ${colorCode};`;
    }

    return style;
}
