import { ActionTree, Module } from 'vuex';
import Company from '@/models/Company';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import CompanyRepository from '@/api/repositories/CompanyRepository';

const companyRepository: CompanyRepository = RepositoryFactory.get('company');

const store = {
    companies: [],
    activeCompany: undefined,
};

const actions: ActionTree<any, any> = {
    /**
     * Loads all companies that are available for current user
     * @param commit
     */
    loadCompaniesAction: async ({commit}) => {
        const companiesRaw = await companyRepository.loadCompanies();
        const companies = Company.parseFromArray(companiesRaw.records);
        commit('storeCompanies', companies);
        return companies;
    },
    loadCompanyOrigin: async ({commit}, companyId: string, skip?: number, limit?: number) => {
        const companiesRaw = await companyRepository.loadCompanyVc(companyId, skip, limit);
        return Company.parseFromArray(companiesRaw.records);
    },
    loadActiveCompanyAction: async ({commit, state}, companyId: string) => {
        const companyRaw = await companyRepository.loadCompany(companyId);
        const company = Company.parseFromObject(companyRaw);
        commit('activeCompany', company);
        return company;
    },
    createCompanyAction: async ({commit}, company: Company) => {
        const rawCompany = await companyRepository.create(company);
        const createdCompany = Company.parseFromObject(rawCompany);
        commit('storeCompany', createdCompany);
        return createdCompany;
    },
    editCompanyAction: async ({commit, state}, company: Company) => {
        const companyCopy = company.parseToObject();
        delete companyCopy.tasks;

        const rawCompany = await companyRepository.edit(companyCopy);
        const editedCompany = Company.parseFromObject(rawCompany);
        commit('storeCompany', editedCompany);

        // Also update the activeCompany (for example for the navigationBar!!
        if (state.activeCompany && state.activeCompany.id === editedCompany.id) {
            commit('activeCompany', editedCompany);
        }

        return editedCompany;
    },

    loadCompanyLogoAction: async ({commit}, companyId: string) => {
        return await companyRepository.loadLogo(companyId);
    },
};

const mutations = {
    storeCompanies: (state: any, companies: Company[]) => state.companies = companies,
    storeCompany: (state: any, company: Company) => {
        const index = state.companies.findIndex((item: Company) => item.id === company.id);
        // replace or add company object
        if (index >= 0) {
            state.companies.splice(index, 1, company);
        } else {
            state.companies.push(company);
        }
    },
    activeCompany: (state: any, company: Company) => state.activeCompany = company,
    reset: (state: any) => {
        state.companies = [];
        state.activeCompany = undefined;
    },
};

const getters = {
    companies: (state: any) => state.companies,
    activeCompany: (state: any) => state.activeCompany,
};

const companyStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default companyStore;
