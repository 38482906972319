import Parseable from '@/misc/Parseable';
import CleanTime from '@/models/CleanTime';
import User from '@/models/User';
import Company from '@/models/Company';
import Location from '@/models/Location';
import Customer from '@/models/Customer';

export interface SubstituteData {
  substitute: Substitute;
  location?: Location | null;
  customer?: Customer | null;
}

export default class Substitute extends Parseable {

  public static parseFromObject(object: Partial<Substitute>): Substitute {
    const substitute = new Substitute();
    Object.assign(substitute, object);
    if (object.cleanTime) {
      substitute.cleanTime = CleanTime.parseFromObject(object.cleanTime);
    }
    if (object.user) {
      substitute.user = User.parseFromObject(object.user);
    }
    if (object.substituteUser) {
      substitute.substituteUser = User.parseFromObject(object.substituteUser);
    }
    return substitute;
  }

  public id!: string;
  public cleanTime?: CleanTime;
  public cleanTimeId?: string;
  public user?: User;
  public userId!: string;
  public substituteUser?: User;
  public substituteUserId?: string;
  public company?: Company;
  public companyId?: string;
  public startDate!: Date;
  public endDate!: Date;

  public parseToObject(): Substitute {
    const tmp: any = {...this};
    tmp.cleanTime = {...this.cleanTime};
    tmp.user = {...this.user};
    tmp.substitute = {...this.substituteUser};
    return tmp;
  }
}
